import { Result } from './result.js';
import { diFetchItem } from './difetch.js';

// Models

export interface ClientProfile {
	hashid: string;
	url: string;
	logo: string;
	schema_name: string;
	static_url: string;
	dashboard_team_url: string;
	dashboard_management_url: string;
	created: Date;
}

// Functions

export async function getClientProfile(): Promise<Result<ClientProfile>> {
	const url = '/api/v1/client_profile/current/';
	return diFetchItem(url);
}
