<script lang="ts">
	import { onMount } from 'svelte';

	import Lang from './utilities/Lang.svelte';
	import * as auth from '$lib/diapi/diauthscorius.js';
	import * as diuser from '$lib/diapi/diuser.js';
	import { loggedIn, userProfile, serverEnvironment, localFilename, clientLogo } from './stores/dataStore';
	import FooterBrand from './FooterBrand.svelte';
	import { getClientProfile } from './diapi/diclient.js';

	let hideOrShowerror: string = 'hidden';
	let errorText: string = 'Placeholder error text';

	async function checkIfLocalOrServerVersion() {
		const url: string = window.location.href;
		let substringURL: string = url.substring(0, 10);
		if (substringURL === 'http://loc') {
			serverEnvironment.set(false);
		} else {
			serverEnvironment.set(true);
		}
		let substringURLCheckDomain: string = url.substring(0, 11);
		if (substringURLCheckDomain === 'https://ctr') {
			window.alert('This is the ctr domain, please use a different domain instead');
		}
	}

	async function checkIfUserIsLoggedIn() {
		if (!$serverEnvironment) {
			const response = await fetch(localFilename('apiUser.json'));
			const data = await response.json();
			loggedIn.set(true);
			userProfile.set(data);
		} else {
			const user = await diuser.getUserProfile();
			if (user.ok === true) {
				loggedIn.set(true);
				userProfile.set(user.value);
				const clientDataResponse = await getClientProfile();
				if (clientDataResponse.ok) {
					const clientData = clientDataResponse.value;
					if(clientData.logo) {
						clientLogo.set(clientData.logo);
					}
				}
			} else {
				auth.login();
			}
		}
	}

	onMount(async () => {
		auth.setCSRF();
		await checkIfLocalOrServerVersion();
		checkIfUserIsLoggedIn();
	});
	// Get current time when the component is rendered
	//const currentDateTime = new Date().toLocaleString();
</script>

<span>connecting...</span>
